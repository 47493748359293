import { Element } from 'react-scroll';
import Navbar from './components/Navbar';
import Home from "./components/Home";
import About from "./components/About";
import Apps from './components/Apps';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
        duration: 700, 
        easing: 'ease-in-out', 
        once: true,
    });
    console.log('AOS initialized');

    const handleRefresh = () => {
      setTimeout(() => {
        AOS.refresh();
        console.log('AOS refreshed');
      }, 500); 
    };
    handleRefresh();
  }, []);
  
  return (
    <>
    <Navbar/>
    <div className="flex-col flex justify-center items-center font-montserrat overflow-x-hidden">
      <Element name="home" className="xl:w-[1280px] w-screen">
          <Home/>
      </Element>
      <Element name="about" className="w-screen">
          <About/>
      </Element>
      <Element name="apps" className="my-24 w-screen bg-slate-200">
          <Apps/>
      </Element>
      <Element name="projects" className="w-screen">
          <Projects/>
      </Element>
      <Element name="contact" className="xl:w-[1280px] w-screen">
          <Contact/>
      </Element >
      <Footer className="w-screen bg-slate-200"
      />
    </div>
    </>
  );
}

export default App;
