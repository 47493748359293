function Footer(){
    return(
    <>
    <section className="w-full mt-28 h-16 flex justify-center items-center bg-slate-200">
        <p>© 2024 John Aldreich Rosales</p>
    </section>
    </>
    );
}

export default Footer;